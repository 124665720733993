/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.featured-posts-carousel').flickity({
          wrapAround: true,
          watchCSS: true,
          prevNextButtons: false,
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_archive' : {
      init: function() {
        function highlightActiveButton(activeButton) {
          var $activeButton = activeButton instanceof jQuery ? activeButton : $(activeButton);
          $('.grid-filters button.btn-filter').removeClass('active');
          $activeButton.addClass('active');
        }

        function filterByHash(hash, $grid) {
          if (!hash) {
            return;
          }

          var filterValue = '.'+hash.substring(1);
          var $filterButton = $(`button.btn-filter[data-filter="${filterValue}"]`);

          // no matching button/filter
          if (!$filterButton.length) {
            return;
          }

          // filter grid
          $grid.isotope({ filter: filterValue });

          // update UI
          $('.grid-filters button.btn-filter').removeClass('active');
          highlightActiveButton($filterButton);

          // scroll to grid
          var content = document.getElementById('content-wrap');
          content.scrollIntoView({
            behavior: 'smooth',
          });
        }

        var currentUrl = window.location.href.split('#')[0];
        var currentHash = window.location.hash;

        var $grid = $('.grid').isotope({
          itemSelector: '.grid-item',
          layoutMode: 'fitRows',
        });

        if (currentHash) {
          filterByHash(currentHash, $grid);
        }

        var gridData = $grid.data('isotope');

        $('.grid-filters').on( 'click', 'button.btn-filter', function() {
          var filterValue = $(this).attr('data-filter');
          var filterHash = filterValue !== '*' ? '#'+filterValue.substring(1) : '';

          window.history.replaceState(null, null, currentUrl + filterHash);

          // Highlight Active Filter
          // $('.grid-filters button.btn-filter').removeClass('active');
          // $(this).addClass('active');
          highlightActiveButton(this);

          // $('.grid-item').each(function() {
          //   if ( $(this).hasClass(filterValue.slice(1)) ) {
          //     $(this).addClass('item-filtered');
          //   } else {
          //     $(this).removeClass('item-filtered');
          //   }
          // });

          // console.log(filterValue);

          $grid.isotope({ filter: filterValue });

          if ( gridData.filteredItems.length === 0) {
            $('.grid-no-results').addClass('show');
          } else {
            $('.grid-no-results').removeClass('show');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_project': {
      init: function() {

        // Keep track of our business markers to show/hide per category
        var businessMarkers = {};
        var projectMarker = null;
        var salesCentreMarker = null;
        var map = {};

        var mapStyles = {};
        mapStyles.standard = {
          styledMapName: "Standard",
          mapTypeId: "standard",
          styles: [{
            "elementType": "geometry",
            "stylers": [{
              "color": "#f5f5f5"
            }]
          }, {
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          }, {
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#f5f5f5"
            }]
          }, {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#bdbdbd"
            }]
          }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          }, {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          }, {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
              "color": "#ffffff"
            }]
          }, {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
              "color": "#dadada"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          }, {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }, {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          }, {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
              "color": "#c9c9c9"
            }]
          }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }]
        };

        function removeInfoWindow(parentOverlay) {
          if (typeof parentOverlay !== "undefined" && parentOverlay !== null) {
            if (typeof parentOverlay.customInfoWindow !== "undefined" && parentOverlay.customInfoWindow !== null) {
              if (parentOverlay.customInfoWindow.getMap() !== null) {
                // Remove from map
                map.removeOverlay(parentOverlay.customInfoWindow);
                parentOverlay.customInfoWindow = null;
              }
            }
          }
        }

        function addCustomMarker(lat, lng, markerContent, infoWindowContent) {
          var customMarker = map.drawOverlay({
            lat: lat,
            lng: lng,
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            content: markerContent,
            click: function(overlay) {
              if (navigator.userAgent.toLowerCase().indexOf('msie') !== -1 && document.all) {
                event.cancelBubble = true;
                event.returnValue = false;
              } else {
                event.stopPropagation();
              }
              google.maps.event.trigger(overlay, 'click', overlay);
            },
            remove: function(el) {
              // If removing this overlay, remove its infowindow overlay if it exists
              if (typeof this.customInfoWindow !== "undefined" && this.customInfoWindow.getMap() !== null) {
                map.removeOverlay(this.customInfoWindow);
              }
              this.el.parentNode.removeChild(this.el);
              this.el = null;
            },
            auto_show: false,
            show: function(el) {
              // Had to set auto_stop to false so that overlays wouldn't get shown again on re-draw, so this is a hack to use zIndex to keep track of the actual state of the overlay
              if (this.el.style.zIndex === "100") {
                this.el.style.display = 'block';
              }
            }
          });

          customMarker.addListener('click', function(overlay) {
            if (typeof overlay.customInfoWindow !== "undefined" && overlay.customInfoWindow !== null) {
              // Remove from map
              map.removeOverlay(overlay.customInfoWindow);
              overlay.customInfoWindow = null;
            } else if (typeof overlay.customInfoWindow === "undefined" || overlay.customInfoWindow === null) {
              overlay.customInfoWindow = map.drawOverlay({
                lat: lat,
                lng: lng,
                layer: 'floatPane',
                verticalAlign: 'top',
                verticalOffset: -27,
                horizontalAlign: 'center',
                horizontalOffset: 11,
                content: '<div class="map-infowindow" style="background:white;"><button class="map-infowindow-close" aria-label="Close Infowindow"><i class="fal fa-times"></i></button>' + infoWindowContent + '</div>'
              });
              overlay.customInfoWindow.addListener('ready', function() {
                $('.map-infowindow-close', this.el).on('click', function(e) {
                  if (navigator.userAgent.toLowerCase().indexOf('msie') !== -1 && document.all) {
                    e.cancelBubble = true;
                    e.returnValue = false;
                  } else {
                    e.stopPropagation();
                  }

                  if (typeof overlay !== "undefined") {
                    if (typeof overlay.customInfoWindow !== "undefined" && overlay.customInfoWindow !== null) {
                      // Remove from map
                      map.removeOverlay(overlay.customInfoWindow);
                      overlay.customInfoWindow = null;
                    }
                  }

                });
              });
            }
          });

          return customMarker;
        }

        // If we have a map to deal with, let's get this party started
        if ($('#development-map').length > 0) {
          // Set up the map, centered on the project location
          map = new GMaps({
            div: '#development-map',
            lat: abstractProject.project.location.lat,
            lng: abstractProject.project.location.lng,
            gestureHandling: "cooperative",
            mapTypeControl: false,
            streetViewControl: false,
            click: function(){
              $.each(businessMarkers, function(businessCategory, businesses) {
                $.each(businesses, function(businessName, business) {
                  // If hiding this overlay, remove its infowindow overlay if it exists
                  removeInfoWindow(business);
                });
              });
              // Same for project and sales centre
              removeInfoWindow(projectMarker);
              removeInfoWindow(salesCentreMarker);
            }
          });

          var mapStyle = abstractProject.mapStyle || 'standard';

          map.addStyle({
            styledMapName: mapStyles[mapStyle].styledMapName,
            styles: mapStyles[mapStyle].styles,
            mapTypeId: mapStyles[mapStyle].mapTypeId
          });

          map.setStyle(mapStyles[mapStyle].mapTypeId);

          var mapBounds = new google.maps.LatLngBounds();

          // Add the project marker
          if (abstractProject.project.location !== null && typeof abstractProject.project.location.lat !== "undefined" && typeof abstractProject.project.location.lng !== "undefined") {
            projectMarker = addCustomMarker(
              abstractProject.project.location.lat,
              abstractProject.project.location.lng,
              '<div class="map-marker map-marker-project" style="background:' + abstractProject.project.location.colour + ';">' + abstractProject.project.logo + '</div>',
              '<p class="marker-title"><b>' + abstractProject.project.location.name + '</b></p><p class="marker-address">' + abstractProject.project.location.address + '</p>'
            );
            mapBounds.extend(new google.maps.LatLng(abstractProject.project.location.lat, abstractProject.project.location.lng));
          }

          // Add the sales centre marker
          if (abstractProject.project.salesCentreLocation !== null && typeof abstractProject.project.salesCentreLocation.lat !== "undefined" && typeof abstractProject.project.salesCentreLocation.lng !== "undefined") {
            salesCentreMarker = addCustomMarker(
              abstractProject.project.salesCentreLocation.lat,
              abstractProject.project.salesCentreLocation.lng,
              '<div class="map-marker map-marker-sales-centre" style="background:' + abstractProject.project.salesCentreLocation.colour + ';">' + abstractProject.project.logo + '</div>',
              '<p class="marker-title"><b>' + abstractProject.project.salesCentreLocation.name + '</b></p><p class="marker-address">' + abstractProject.project.salesCentreLocation.address + '</p>'
            );
            mapBounds.extend(new google.maps.LatLng(abstractProject.project.salesCentreLocation.lat, abstractProject.project.salesCentreLocation.lng));
          }

          // Add the business markers
          $.each(abstractProject.businesses, function(businessCategorySlug, businessCategory) {
            $.each(businessCategory.businesses, function(businessIndex, business) {
              if (typeof business.location.lat !== "undefined" && typeof business.location.lng !== "undefined") {
                businessMarkers[businessCategory.slug] = businessMarkers[businessCategory.slug] || {};
                businessMarkers[businessCategory.slug][business.slug] = addCustomMarker(
                  business.location.lat,
                  business.location.lng,
                  '<div class="map-marker map-marker-category map-marker-' + businessCategory.slug + '" style="background:' + businessCategory.colour + ';"><i class="fal fa-' + businessCategory.icon + '"></i></div>',
                  '<p class="marker-title"><b>' + business.name + '</b></p><p class="marker-description">' + business.description + '</p><p class="marker-address">' + business.location.address + '</p>'
                );
                mapBounds.extend(new google.maps.LatLng(business.location.lat, business.location.lng));
              }
            });
          });

          map.map.fitBounds(mapBounds);

          // Filter button click handler
          $('.map-category-button').click(function(e) {
            var mapCategory = $(this).data('business-category');

            $.each(businessMarkers, function(businessCategory, businesses) {
              $.each(businesses, function(businessName, business) {
                if (businessCategory === mapCategory || mapCategory === 'all') {
                  business.el.style.display = 'block';
                  // zIndex as hack to keep track of true shown/hidden state of overlay
                  business.el.style.zIndex = 100;
                } else {
                  business.el.style.display = 'none';
                  business.el.style.zIndex = 99;
                  // If hiding this overlay, remove its infowindow overlay if it exists
                  if (typeof business.customInfoWindow !== "undefined" && business.customInfoWindow !== null) {
                    map.removeOverlay(business.customInfoWindow);
                    business.customInfoWindow = null;
                  }
                }
              });
            });

            removeInfoWindow(projectMarker);
            removeInfoWindow(salesCentreMarker);
          });
        }
      },
      finalize: function() {
        // Sticky Nav Tricks
        var $primaryNav = $('.banner');
        var $pageHeaderNavbar = $('.page-header-navbar');

        if ($pageHeaderNavbar.length) {
          if (Waypoint.Sticky) {
            new Waypoint.Sticky({
              element: $pageHeaderNavbar[0],
              wrapper: false
            });

            $pageHeaderNavbar.waypoint({
              offset: function() {
                return $primaryNav.outerHeight();
              },
              handler: function(direction) {
                if (direction === 'down') {
                  var offset = $('#stickyWrapper').offset().top - $primaryNav.outerHeight();
                  $primaryNav.css({
                    'top': offset,
                    'position': 'absolute'
                  });
                } else {
                  $primaryNav.attr('style', '');
                }
              }
            });
          }
        }
      }
    },
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // If custom fonts are defined, load 'em up!
        if (abstractSite !== undefined && abstractSite.fonts !== undefined && abstractSite.fonts !== null &&
          ( !$.isArray(abstractSite.fonts) && !$.isEmptyObject(abstractSite.fonts) ) ) {
          WebFont.load(abstractSite.fonts);
        } else {
          $('html').addClass('wf-inactive');
        }

        $('button.toggle-menu').on('click', function() {
          $('body').toggleClass('menu-expanded');
        });

        // URL updates and the element focus is maintained
        // originally found via in Update 3 on http://www.learningjquery.com/2007/10/improved-animated-scrolling-script-for-same-page-links
        // from: https://css-tricks.com/smooth-scrolling-accessibility/

        // filter handling for a /dir/ OR /indexordefault.page
        function filterPath(string) {
          return string
            .replace(/^\//, '')
            .replace(/(index|default).[a-zA-Z]{3,4}$/, '')
            .replace(/\/$/, '');
        }

        var locationPath = filterPath(location.pathname);

        // $('a[href*="#"]:not(.ab-item)').each(function () {
        //   var thisPath = filterPath(this.pathname) || locationPath;
        //   var hash = this.hash;
        //   var $target = $(hash);
        //   var bannerOffset = 0;

        //   if ( $(this)[0].id === 'scrollTop' ) {
        //     $(this).on('click', function (event) {
        //       $('html, body').stop().animate({scrollTop: 0}, 1000);
        //     });
        //   } else if ($target.length && typeof($(this).data('vceTarget')) === "undefined") {
        //     if (locationPath === thisPath && (location.hostname === this.hostname || !this.hostname) && this.hash.replace(/#/, '')) {
        //       var target = this.hash;

        //       if (target) {
        //         $(this).on('click', function (event) {
        //           event.preventDefault();

        //           if ( $('.page-header-navbar').hasClass('stuck') ) {
        //             bannerOffset = $('.page-header-navbar').outerHeight(true);
        //           } else {
        //             bannerOffset = $('.banner').outerHeight(true);
        //           }

        //           if ( $('body').hasClass('admin-bar') ) {
        //             bannerOffset += $('#wpadminbar').outerHeight(true);
        //           }

        //           // Set the hash in the current URL without pushing a history entry
        //           history.replaceState(undefined, undefined, target);

        //           $('html, body').stop().animate({scrollTop: $target.offset().top - bannerOffset}, 1000);
        //         });
        //       }
        //     }
        //   }
        // });

        function playSelectedVideo(flkty) {
          if (flkty) {
            $(flkty.element).find('video').each(function() {
              $(this).get(0).pause();
            });

            // Start just the current video
            var $selectedCell = flkty.selectedCell;
            if ($($selectedCell.element).find('video').length){
              $($selectedCell.element).find('video').get(0).play();
            }
          }
         }

        // Page Header Carousel
        if ($('.page-header.carousel').length) {
          var pageHeaderFlickity;

          // https://github.com/metafizzy/flickity/issues/277
          $('.page-header.carousel').each(function(i, elem) {
            var $elem = $(elem);
            var optionsAttr = $elem.attr('data-flickity-options');
            var options = optionsAttr ? JSON.parse( optionsAttr ) : {};
            options.prevNextButtons = false;
            options.wrapAround = true;
            options.cellSelector = '.carousel-cell';
            pageHeaderFlickity = $elem.flickity(options);
          });

          var flkty = pageHeaderFlickity.data('flickity');

          // After Flickity initialized play video in selected cell
          playSelectedVideo(flkty);

          pageHeaderFlickity.on( 'settle.flickity', function( event, index ) {
            playSelectedVideo(flkty);
          });
        }

        $('#watchVideo').on('show.bs.modal', function (event) {
          $(this).find('.video').addClass('loading');
        });

        $('#watchVideo').on('shown.bs.modal', function (event) {
          var button = $(event.relatedTarget);
          var iframeRaw = button.data('video');
          var videoContainer = $(this).find('.video');

          // Make into jQuery object
          var iframe = $(iframeRaw);
          // Store iframe source
          var iframeSrc = iframe.attr('src');

          // Remove source so iframe isn't loaded when we put it into the container
          iframe.attr('src', '');

          $(iframe).appendTo(videoContainer).on('load', function() {
            videoContainer.removeClass('loading');
          });

          // Fallback if for some reason the above doesn't work
          setTimeout(function() {
            videoContainer.removeClass('loading');
          }, 2000);

          // Add the source back
          iframe.attr('src', iframeSrc);
        });

        $('#watchVideo').on('hidden.bs.modal', function (event) {
          var iframe = $(event.target).find('iframe');

          iframe.remove();
        });

        // Polyfill for IE11 for medium zoom
        // Production steps of ECMA-262, Edition 6, 22.1.2.1
        if (!Array.from) {
          Array.from = (function () {
            var toStr = Object.prototype.toString;
            var isCallable = function (fn) {
              return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
            };
            var toInteger = function (value) {
              var number = Number(value);
              if (isNaN(number)) { return 0; }
              if (number === 0 || !isFinite(number)) { return number; }
              return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
            };
            var maxSafeInteger = Math.pow(2, 53) - 1;
            var toLength = function (value) {
              var len = toInteger(value);
              return Math.min(Math.max(len, 0), maxSafeInteger);
            };

            // The length property of the from method is 1.
            return function from(arrayLike/*, mapFn, thisArg */) {
              // 1. Let C be the this value.
              var C = this;

              // 2. Let items be ToObject(arrayLike).
              var items = Object(arrayLike);

              // 3. ReturnIfAbrupt(items).
              if (arrayLike == null) {
                throw new TypeError('Array.from requires an array-like object - not null or undefined');
              }

              // 4. If mapfn is undefined, then let mapping be false.
              var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
              var T;
              if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                  throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                  T = arguments[2];
                }
              }

              // 10. Let lenValue be Get(items, "length").
              // 11. Let len be ToLength(lenValue).
              var len = toLength(items.length);

              // 13. If IsConstructor(C) is true, then
              // 13. a. Let A be the result of calling the [[Construct]] internal method
              // of C with an argument list containing the single item len.
              // 14. a. Else, Let A be ArrayCreate(len).
              var A = isCallable(C) ? Object(new C(len)) : new Array(len);

              // 16. Let k be 0.
              var k = 0;
              // 17. Repeat, while k < len… (also steps a - h)
              var kValue;
              while (k < len) {
                kValue = items[k];
                if (mapFn) {
                  A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                  A[k] = kValue;
                }
                k += 1;
              }
              // 18. Let putStatus be Put(A, "length", len, true).
              A.length = len;
              // 20. Return A.
              return A;
            };
          }());
        }

        $('a[data-zoom]').each(function() {
          var $button = $(this);
          var zoomTarget = $button.attr('data-zoom');
          var zoom = mediumZoom(zoomTarget);

          $button.on('click', function(e) {
            e.preventDefault();
            zoom.open();
          });
        });

        //mediumZoom('[data-action="zoom"]');

        $('button[data-action="floorplan-select"]').on('click', function(e) {
          // The button you clicked
          var button = e.target;

          // Next and Previous Lists in Menu (structured like nested list)
          var $prevList = $(button).parents('.floorplan-select');
          var $nextList = $(button).siblings('.floorplan-select');

          // Where are we?
          var prevLevel = $prevList.attr('data-level');
          var nextLevel = $nextList.attr('data-level');

          // If there is a level below this, add classes to slide menu
          if ( $('.floorplan-select[data-level="' + nextLevel + '"]').length ) {
            $('.floorplan-select[data-level="0"]').attr("data-viewing", nextLevel);
          } else {
            // Which floorplan do you wanna look at?
            var targetFloorplanID = '#' + $(button).attr('data-target');

            $('button[data-action="floorplan-select"]').removeClass('active');
            $(button).addClass('active');

            // Loop through all floorplans and add class to chosen floorplan
            $('.floorplan').each(function() {
              if ( $(this).is(targetFloorplanID) ) {
                $(this).addClass('active');
              } else {
                $(this).removeClass('active');
              }
            });
          }

          // Show Controls
          if ( nextLevel > 0 ) {
            $('.floorplan-side-header').addClass('show-controls');
          }

          // Add active classes to show path
          if ( $nextList.length) {
            //console.log('showing next');
            $nextList.addClass('active');
          }
        });

        $('.floorplan-back-button').on('click', function(e) {
          var currentLevel = $('.floorplan-select[data-level="0"]').attr('data-viewing');

          $('.floorplan-select .floorplan-select-button').removeClass('active');

          if ( currentLevel > 0 ) {
            var targetLevel = currentLevel - 1;

            // Set which level is being viewed
            $('.floorplan-select[data-level="0"]').attr("data-viewing", targetLevel);

            // Remove Active class after waiting until it no longer visible
            setTimeout(function() {
              $('.floorplan-select[data-level="'+ currentLevel +'"]').removeClass('active');
            }, 200);

            if ( targetLevel === 0 ) {
              $('.floorplan-side-header').removeClass('show-controls');
            }

            // If viewing a floorplan, remove active class from it
            if ( $('.floorplan').hasClass('active') ) {
              $('.floorplan').removeClass('active');
            }
          }
        });

        // Google Forms Fade in/Out sorta
        $(document).bind('gform_post_render', function(e, form_id, current_page){
          $('#gform_' + form_id).submit(function () {
            //console.log('FORM: Fade Out');
            $('.custom-form-wrapper').css('min-height', $('.custom-form-wrapper').height()).addClass('submitting');
          });

          setTimeout(function() {
            //console.log('FORM: Fade In');
            $('.custom-form-wrapper').removeClass('submitting');
          }, 400);
        });

        // Back to Top Button
        $('#content-wrap').waypoint({
          handler: function(direction) {
            if ( direction === 'down' ) {
              $('#scrollTop').addClass('show');
            } else {
              $('#scrollTop').removeClass('show');
            }
          }
        });

        // Countdown Timer
        var countdownBanner = document.querySelector('.countdown-banner');

        if ( countdownBanner !== null ) {
          var clock = countdownBanner.querySelector('#countdownTimerClock');

          if ( clock ) {
            var deadline = new Date(Date.parse(clock.dataset.end));

            // initializeClock('countdownTimerClock', deadline);
            if ( countdownBanner !== null ) {
              const timer = new CountdownTimer({
                selector: "#countdownTimerClock",
                targetDate: deadline,
              });

              timer.startTimer();
            }

            $('.countdown-banner-wrapper').on('hidden.bs.collapse', function () {
              // Set cookie
              var expires = new Date();

              // Set expiry for a week
              expires.setHours(expires.getHours() + (7 * 24));

              var cookie = [
                'dismissed'+this.id+'=true',
                'expires=' + expires.toUTCString(),
                'path=/',
              ];

              document.cookie = cookie.join(';');
            });
          }
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  // $(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
